import classNames from 'classnames';
import { reportEvent } from '../../../../services/analyticsService';
import { PlacesAutoComplete, ZorbaButton } from '../../../../components/forms';
import LazyImage from '../../../../components/layout/LazyImage';
import styles from './index.module.scss';

export default (props) => {
  const {
    parentStyles,
    variantData,
    placesLoader,
    isHomeownerMode,
    placeValue,
    setPlaceValue,
    title = "How it Works?"
  } = props;
  return (
    <div
      className={classNames(styles.section, styles['how-it-works'], [
        props.className,
      ])}
    >
      <div className={styles['section-inner']}>
        <h3>{title}</h3>
        <div className={styles.items}>
          <div className={styles.left}>
            <LazyImage
              src="/img/landing-pages/homeowners/how-it-works-bg.png"
              className={styles['left-bg']}
              alt="How it Works?"
            />
            {props.frontImg && <img
              src={props.frontImg || '/img/landing-pages/homeowners/phone.png'}
              alt="Your Listing"
            />}
          </div>
          <div className={classNames(styles.right, styles.steps)}>
            {/* STEP 1 */}
            {variantData.howItWorksItems.map((item, idx) => (
              <div key={idx} className={styles['step-item']}>
                <div className={styles['number-wrap']}>
                  <div className={styles.number}>{idx + 1}</div>
                </div>
                <div className={styles.content}>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.desc}>{item.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['bottom-part']}>
          <h4>{variantData.howItWorksBottomTitle}</h4>
          {props.cta || (
            <div className={styles['places-wrapper']}>
              <div className={styles['place-input-wrapper']}>
                <PlacesAutoComplete
                  clearInputOnSelect={false}
                  clearInputOnBlur={false}
                  showIcon={false}
                  title=""
                  placeholder="Enter your property address"
                  onChange={props.onPlaceSelect}
                  allowFullAddress
                  initialInputText={placeValue?.formattedAddress || ''}
                  placesAutoCompleteStyle={styles['places-auto-complete']}
                />

                <ZorbaButton
                  className={styles['place-submit-btn']}
                  loading={placesLoader}
                  onClick={() => {
                    reportEvent('auth', 'login', {
                      source: 'homeownersLandingPage',
                      type: 'heroSection',
                    });
                    window.location.href = 'https://app.getzorba.com/login';
                  }}
                >
                  Get Started
                </ZorbaButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
