import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LazyImage = ({ className, alt, width, height, src }) => (
  <div>
    <LazyLoadImage
      alt={alt}
      height={height}
      src={src} // use normal <img> attributes as props
      width={width}
      effect="blur"
      className={className}
      delayTime={5000}
    />
    {/* <span>{image.caption}</span> */}
  </div>
);

export default LazyImage;
